/* Navbar CSS */
.navbar {
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    width: 100%;
    border-bottom: 1px solid #D2D2D2;
}

.navbar-link {
    margin-left: 2rem;
    background: none;
    text-decoration: none;
}

